import React from 'react';
import { FaMedium } from 'react-icons/fa6';
import { FiLinkedin, FiGithub } from 'react-icons/fi';
import { Box, Text, Heading, HStack, Icon } from '@chakra-ui/react';

export const Imprint = () => {
 return (
  <Box position='relative' paddingY='20' pb='0'>
   <Box maxWidth='6xl' marginX='auto'>
    <Box>
     <Heading marginBottom='6' fontSize='40px' fontFamily={'alternate'}>
      Jide Abdul-Qudus
     </Heading>
     <Text fontSize='lg'>Crafting Code, Engineering the Future, One line at a Time.</Text>
    </Box>

    <HStack marginTop='9' spacing={{ base: '8', md: '10' }}>
     <HStack
      as='a'
      href={'https://www.linkedin.com/in/jideabdqudus/'}
      rel='noopener'
      target='_blank'
      spacing='3'>
      <Icon aria-hidden as={FiLinkedin} fontSize='xl' color={'yellow.50'} />
      <Text textDecoration='underline' textDecorationThickness='1px' textUnderlineOffset='3px'>
       LinkedIn
      </Text>
     </HStack>

     <HStack
      as='a'
      href={'https://medium.com/@jideabdqudus'}
      rel='noopener'
      target='_blank'
      spacing='3'>
      <Icon aria-hidden as={FaMedium} fontSize='xl' color={'yellow.50'} />
      <Text textDecoration='underline' textDecorationThickness='1px' textUnderlineOffset='3px'>
       Medium
      </Text>
     </HStack>

     <HStack
      as='a'
      href={'https://github.com/jideabdqudus'}
      rel='noopener'
      target='_blank'
      spacing='3'>
      <Icon aria-hidden as={FiGithub} fontSize='xl' color={'yellow.50'} />
      <Text textDecoration='underline' textDecorationThickness='1px' textUnderlineOffset='3px'>
       Github
      </Text>
     </HStack>
    </HStack>
   </Box>
  </Box>
 );
};

export default Imprint;
