import React from 'react';
import { Box, Heading, HStack, LinkBox, Image, Link, SimpleGrid } from '@chakra-ui/react';

interface IProps {
 allFeaturedArticlesJson: Queries.IndexPageQuery['allFeaturedArticlesJson'];
}
const FeaturedArticle = ({ allFeaturedArticlesJson }: IProps) => {
 return (
  <Box>
   <Box marginBottom='8'>
    <Heading fontSize='40px' letterSpacing='tight' fontFamily={'alternate'}>
     Featured Articles 📝
    </Heading>
   </Box>
   <SimpleGrid columns={{ base: 1, md: 3 }} mt='4rem' spacing='10'>
    {allFeaturedArticlesJson?.edges?.map(({ node }) => (
     <LinkBox key={node.id}>
      <Box height='210px' rounded='lg' overflow='hidden' position='relative'>
       <Image
        src={node.ogImage || ''}
        alt={'img'}
        width='340'
        height='210'
        objectFit='cover'
       />
      </Box>
      <Box flex='1' mt='5'>
       <HStack spacing='5' fontSize='sm'>
        <HStack spacing='2' color='yellow.50'>
         <Box as='time'>{node.date}</Box>
         <span aria-hidden>•</span>
         <Box>{`${node.readingTime} read`}</Box>
        </HStack>
       </HStack>
       <Heading size='lg' fontWeight='semibold' marginY='4'>
        <Link href={node.link || ''} isExternal>
         {node.title || ''}
        </Link>
       </Heading>
      </Box>
     </LinkBox>
    ))}
   </SimpleGrid>
  </Box>
 );
};

export default FeaturedArticle;
