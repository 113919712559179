import React from 'react';
import { FiGithub, FiGlobe } from 'react-icons/fi';
import {
 Box,
 HStack,
 Text,
 Image,
 Link,
 Icon,
 Button,
 Heading,
 SimpleGrid,
} from '@chakra-ui/react';

interface IProps {
 allOpenSourceJson: Queries.IndexPageQuery['allOpenSourceJson'];
}
const FeaturedProject = ({ allOpenSourceJson }: IProps) => {
 return (
  <Box as='section' py='vGutter'>
   <Box marginBottom='8'>
    <Heading fontSize='40px' letterSpacing='tight' fontFamily={'alternate'}>
     Featured Open Source 🚀
    </Heading>
   </Box>
   <SimpleGrid columns={{ base: 1, md: 2 }} mt='4rem' spacing='10'>
    {allOpenSourceJson.edges.map(({ node }) => (
     <Box
      display='flex'
      gap='8'
      data-group
      padding={{ base: '5' }}
      mb='8'
      rounded='xl'
      style={{
       border: '1px dashed #fed304',
      }}
      key={node.id}>
      <Box display={{ base: 'none', md: 'flex' }} alignItems='center' borderRadius={100}>
       <Image src={node.logo || ''} alt={`${node.name} logo`} width='20' />
      </Box>
      <Box>
       <Text fontSize='20px' fontWeight='bold' fontFamily={'subheading'} color='yellow.50'>
        {node.name || ''}
       </Text>
       <Text mt='4' mb='8'>
        {node.about || ''}
       </Text>
       <HStack gap='4'>
        <Link href={node.github || ''} isExternal={true}>
         <Button variant={'link'} color='gray.300'>
          <Icon as={FiGithub} color='gray.300' mr='8px' />
          Github
         </Button>
        </Link>
        <Link href={node.website || ''} isExternal={true}>
         <Button variant={'link'} color='gray.300'>
          <Icon as={FiGlobe} color='gray.300' mr='8px' />
          Website
         </Button>
        </Link>
       </HStack>
      </Box>
     </Box>
    ))}
   </SimpleGrid>
  </Box>
 );
};

export default FeaturedProject;
