import {
 Box,
 Heading,
 Text,
 Button,
 Divider,
 Tag,
 HStack,
 Flex,
 Icon,
 Circle,
 Image,
 Link as ChakraLink,
 VStack,
 Container,
} from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import { formatDate } from 'src/utils/format-date';
// import { Disqus } from 'gatsby-plugin-disqus';
import { useMDX } from 'src/utils/use-mdx';
import AppWrapper from 'src/components/app-wrapper';
import MDXComponents from 'src/components/mdx-components';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FiArrowLeft, FiTwitter, FiLinkedin, FiGithub, FiExternalLink } from 'react-icons/fi';

interface IProps {
 data: Queries.PostDetailsQuery;
}
const PostDetails = ({ data }: IProps) => {
 const fetchImage: any = (image: IGatsbyImageData) => {
  return getImage(image);
 };

 const mdx = useMDX(data.mdx?.body);
 const Content = mdx?.default;

 return (
  <AppWrapper active='Blog'>
   <SEO
    title={data?.mdx?.frontmatter?.title}
    type='article'
    description={data?.mdx?.frontmatter?.excerpt}
    pathname={`blog/${data?.mdx?.frontmatter?.slug}`}
    image={
     data?.mdx?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
     ''
    }
   />
   <Box ml={'5'}>
    <Link to='/blog'>
     <Icon
      as={FiArrowLeft}
      mt='1px'
      color='black'
      bg='yellow.50'
      height={'40px'}
      width='40px'
      rounded={'5px'}
     />
    </Link>
   </Box>
   <Container maxW={'4xl'}>
    <Box>
     <Heading as='h1' mb='4' mt='16'>
      {data?.mdx?.frontmatter?.title}
     </Heading>
     <Text mb='8' fontSize={'20px'}>
      {data?.mdx?.frontmatter?.excerpt}
     </Text>
     <Flex justifyContent={'space-between'}>
      <Box>
       {data?.mdx?.frontmatter?.readingTime} mins read •{' '}
       {formatDate(data?.mdx?.frontmatter?.date || '', true)}.
      </Box>
      <Box>
       <HStack spacing={3} mb='8'>
        {data?.mdx?.frontmatter?.tag?.map((t: any, idx) => (
         <Tag background={'yellow.50'} key={idx}>
          # {t}
         </Tag>
        ))}
       </HStack>
      </Box>
     </Flex>
     <Divider mb='8' color='whiteAlpha.300' opacity={0.2} />
     <Box mb='20'>
      <GatsbyImage image={fetchImage(data?.mdx?.frontmatter?.image)} alt={'Alt text'} />
     </Box>
     <Box className='markdown-body prism'>
      <Content components={MDXComponents} />
     </Box>
     <Box mb='16' mt='16'>
      <Flex justifyContent={'center'}>
       <VStack>
        <QRCode
         value={`http://twitter.com/intent/tweet?text=@jideabdqudus just posted a new article https://abdulqudus.com/blog/${data?.mdx?.frontmatter?.slug}`}
         bgColor={'#fed304'}
         viewBox={`0 0 100 100`}
         size={100}
        />
        <Text>
         <a
          href={`http://twitter.com/intent/tweet?text=@jideabdqudus just posted a new article https://abdulqudus.com/blog/${data?.mdx?.frontmatter?.slug}`}
          target='_blank'
          rel='noopener noreferrer'>
          Share on X (Twitter) <Icon as={FiExternalLink} pt='6px' color='yellow.50' />
         </a>
        </Text>
       </VStack>
      </Flex>
     </Box>
    </Box>
    {/* <Disqus
     config={{
      url: `https://abdulqudus.com/${data?.mdx?.frontmatter?.slug}`,
      identifier: data?.mdx?.frontmatter?.slug,
      title: data?.mdx?.frontmatter?.title,
     }}
    /> */}
   </Container>
   <Divider color='whiteAlpha.300' opacity={0.2} />
   <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '4', md: '8' }} mt='16'>
    <Circle size='80px' overflow='hidden'>
     <Image alt='jideabdqudus' src='/images/avatar.jpg' width='80px' height='80px' />
    </Circle>
    <Box>
     <Heading size='md'>Written by Jide Abdul-Qudus</Heading>
     <Text mt='4' lineHeight='taller' textAlign={'justify'}>
      I'm Jide, a Full-Stack Software Engineer with penchant for Web/App development. I like
      scratching my own itch and writing about Web Technologies, UI/UX case studies, and
      Tech-bits thereof. During my spare time, I'm an autodidact polymath acquiring knowledge
      from various resources online.
     </Text>
     <HStack mt='4'>
      <ChakraLink
       href={'https://github.com/jideabdqudus'}
       rel='noopener noreferrer'
       isExternal={true}>
       <Button variant={'link'} color='yellow.50'>
        <Icon as={FiGithub} color='yellow.50' _hover={{ color: 'yellow.100' }} />
       </Button>
      </ChakraLink>
      <ChakraLink
       href={'https://twitter.com/jideabdqudus'}
       rel='noopener noreferrer'
       isExternal={true}>
       <Button variant={'link'} color='yellow.50'>
        <Icon as={FiTwitter} color='yellow.50' _hover={{ color: 'yellow.100' }} />
       </Button>
      </ChakraLink>
      <ChakraLink
       href={'https://www.linkedin.com/in/jideabdqudus/'}
       rel='noopener noreferrer'
       isExternal={true}>
       <Button variant={'link'} color='yellow.50'>
        <Icon as={FiLinkedin} color='yellow.50' _hover={{ color: 'yellow.100' }} />
       </Button>
      </ChakraLink>
     </HStack>
    </Box>
   </Flex>
  </AppWrapper>
 );
};

export default PostDetails;

export const query = graphql`
 query PostDetails($slug: String!) {
  mdx(frontmatter: { slug: { eq: $slug } }) {
   id
   body
   frontmatter {
    date
    date
    excerpt
    slug
    section
    tag
    readingTime
    title
    image {
     childImageSharp {
      gatsbyImageData(placeholder: BLURRED, quality: 100, formats: AUTO)
     }
    }
   }
  }
 }
`;
