import React from 'react';
import { FiMail } from 'react-icons/fi';
import { domHtml } from 'src/utils/strip-tags';
import { Box, Flex, Square, Text, Icon, Button, Heading, Avatar } from '@chakra-ui/react';

interface IProps {
 bioDataJson: Queries.IndexPageQuery['bioDataJson'];
}
const BioSection = ({ bioDataJson }: IProps) => {
 const { details, about, email } = bioDataJson!;
 return (
  <Flex
   paddingY={{ base: '10px', md: 'vGutter' }}
   gap={{ base: '5', lg: '20' }}
   justify='space-between'
   direction={{ base: 'column', lg: 'row' }}>
   <Box>
    <Square
     position={'relative'}
     rounded='xl'
     padding={{ base: '0px', lg: '10px' }}
     margin={{ base: '0px' }}
     marginRight={{ base: '0px', lg: '6px' }}
     mt={{ base: '0', md: '20' }}>
     <Avatar
      name='jide'
      src={'/images/avatar.jpg'}
      size='full'
      padding='2px'
      height={{ base: '200px', md: '400px' }}
      width={{ base: '200px', md: '400px' }}
     />
    </Square>
   </Box>
   <Box margin='4'>
    {details && (
     <Heading
      fontSize={{ base: '2rem', md: '3rem' }}
      letterSpacing='tight'
      mb='8'
      fontFamily={'alternate'}
      color='yellow.50'>
      {details[0]?.subtag?._1}
      <Box as='span' color='white.100'>
       {details[0]?.subtag?._2}
      </Box>
      {details[0]?.subtag?._3}
      <Box as='span' color='white.100'>
       {details[0]?.subtag?._4}
      </Box>
     </Heading>
    )}
    <Text
     fontSize={{ base: 'lg', md: '1xl' }}
     textAlign='justify'
     dangerouslySetInnerHTML={domHtml(about || '')}
    />
    <Button
     as={'a'}
     mt='20px'
     fontSize={'md'}
     fontWeight={400}
     href={`mailto:${email}`}
     _hover={{ color: 'yellow.50' }}
     textDecoration='underline'
     textUnderlineOffset={'5px'}
     color={'gray.200'}
     variant='link'>
     <Icon as={FiMail} color='yellow.50' mr='8px' /> {email}
    </Button>
   </Box>
  </Flex>
 );
};

export default BioSection;
