import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import { FiArrowLeft } from 'react-icons/fi';
import AppWrapper from 'src/components/app-wrapper';
import { Box, Heading, Text, Divider, Icon, Container } from '@chakra-ui/react';

interface IProps {
 data: Queries.BookmarkDetailsQuery;
}
const BookmarkDetails = ({ data }: IProps) => {
 return (
  <AppWrapper active='Bookmarks'>
   <SEO
    title={data?.markdownRemark?.frontmatter?.title}
    description={data?.markdownRemark?.frontmatter?.excerpt}
    pathname={`bookmarks/${data?.markdownRemark?.frontmatter?.slug}`}
    image={data?.markdownRemark?.frontmatter?.images}
   />
   <Box>
    <Link to='/bookmarks'>
     <Icon
      as={FiArrowLeft}
      mt='1px'
      color='black'
      bg='yellow.50'
      height={'40px'}
      width='40px'
      rounded={'5px'}
     />
    </Link>
    <Container maxW={'4xl'}>
     <Heading as='h1' mb='4' mt='16'>
      {data?.markdownRemark?.frontmatter?.title}
     </Heading>
     <Text mb='8' fontSize={'20px'}>
      {data?.markdownRemark?.frontmatter?.excerpt}
     </Text>
     <Divider mb='8' color='whiteAlpha.300' opacity={0.2} />
     <Box className='markdown-body'>
      <article dangerouslySetInnerHTML={{ __html: data?.markdownRemark?.html || '' }} />
     </Box>
    </Container>
   </Box>
  </AppWrapper>
 );
};

export default BookmarkDetails;

export const query = graphql`
 query BookmarkDetails($slug: String!) {
  markdownRemark(frontmatter: { slug: { eq: $slug } }) {
   html
   frontmatter {
    tag
    title
    date
    excerpt
    slug
   }
  }
 }
`;
