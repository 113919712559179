import React from 'react';
import { SEO } from 'src/components/seo';
import { graphql, PageProps } from 'gatsby';
import Imprint from 'src/components/imprint';
import AppWrapper from 'src/components/app-wrapper';
import BioSection from 'src/components/bio-section';
import NewsLetter from 'src/components/newsletter';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import FeaturedProject from 'src/components/featured-proj-card';
import FeaturedArticle from 'src/components/featured-article-card';
import { Box, Flex, Heading, HeadingProps, Text } from '@chakra-ui/react';

const Index = ({ data: graphQuery }: PageProps<Queries.IndexPageQuery>) => {
 const { allOpenSourceJson, allFeaturedArticlesJson, bioDataJson } = graphQuery;
 return (
  <AppWrapper active='Home'>
   <SEO
    title={'Jide Abdul-Qudus (The Qoder) - Software Engineer'}
    description={
     'Self taught Software Engineer creating expressive and interactive web experiences for all'
    }
    image={`images/web-ogimage1.jpg`}
   />
   <Flex direction={'column'} pt='32' pb='22' ml='18'>
    <MainHeading>
     Hello,
     <DotLottieReact
      src={'/assets/straight-face.json'}
      className='straight-face-hidden'
      loop
      autoplay
      backgroundColor='transparent'
      speed={1}
     />
    </MainHeading>
    <MainHeading>
     I'm Jide
     <DotLottieReact
      src={'/assets/straight-face.json'}
      className='straight-face'
      loop
      autoplay
      backgroundColor='transparent'
      speed={1}
     />
    </MainHeading>
    <Heading fontSize={'2xl'} color='yellow.50'>
     Full-Stack Developer & Technical Writer.
    </Heading>
    <Text mt={{ base: '5', md: '14' }} fontSize={{ base: 'lg', md: '2xl' }}>
     I'm passionate about bringing 🎨 design to life through 👨🏽‍💻 code , <br />
     and building delightful 🌐 web experiences.
    </Text>
   </Flex>

   <Box as='section'>
    <BioSection bioDataJson={bioDataJson} />
   </Box>

   <Box as='hr' borderColor='whiteAlpha.300' />

   <Box as='section'>
    <FeaturedProject allOpenSourceJson={allOpenSourceJson} />
   </Box>

   <Box as='hr' borderColor='whiteAlpha.300' />

   <Box as='section' py='vGutter'>
    <FeaturedArticle allFeaturedArticlesJson={allFeaturedArticlesJson} />
   </Box>

   <Box as='hr' borderColor='whiteAlpha.300' />

   <Box as='section'>
    <NewsLetter />
   </Box>

   <Box as='hr' borderColor='whiteAlpha.300' />

   <Box as='section'>
    <Imprint />
   </Box>
  </AppWrapper>
 );
};

function MainHeading(props: HeadingProps) {
 return (
  <Heading
   display={'flex'}
   width='full'
   as='h1'
   fontFamily={'alternate'}
   letterSpacing='tight'
   lineHeight='1'
   userSelect='none'
   textAlign={'center'}
   marginBottom='4'
   fontSize={{ base: '65px', md: '90px' }}
   float={'left'}
   {...props}
  />
 );
}

export default Index;

export const query = graphql`
 query IndexPage {
  allOpenSourceJson {
   edges {
    node {
     id
     logo
     name
     about
     github
     website
    }
   }
  }
  allToolsJson {
   edges {
    node {
     language
     label
     id
    }
   }
  }
  allFeaturedArticlesJson {
   edges {
    node {
     link
     ogImage
     readingTime
     title
     id
     date
    }
   }
  }
  bioDataJson {
   id
   name
   email
   about
   tagline
   details {
    subtag {
     _1
     _2
     _3
     _4
    }
   }
  }
 }
`;
