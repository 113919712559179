import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import AppWrapper from 'src/components/app-wrapper';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Heading, Box, Text, LinkBox, SimpleGrid } from '@chakra-ui/react';

interface IProps {
 data: Queries.SnippetListQuery;
}

const Snippets = ({ data }: IProps) => {
 const posts: Queries.SnippetListQuery['snippets'] = data.snippets;
 const fetchImage: any = (image: IGatsbyImageData) => {
  return getImage(image);
 };
 return (
  <AppWrapper active='Snippets'>
   <SEO title='Snippets - Jide Abdul-Qudus - Software Engineer' pathname='snippets' />
   <Box>
    <Heading fontSize={{ base: '2rem', md: '3rem' }} letterSpacing='tight' mb='8' mt='8' fontFamily={'alternate'}>
     Snippets
    </Heading>
    <Box maxW={{ lg: '500' }}>
     <Text fontSize={{ base: 'lg', md: '1xl' }}>
      A collection of code snippets I've used in the past and saved, ranging from random CSS
      snippets to utility functions and scripts
     </Text>
    </Box>
   </Box>
   <Box mt={'50px'}>
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing='10'>
     {posts.edges.map(({ node }: any) => (
      <Link to={`/snippets/${node?.frontmatter?.slug}`} key={node.id}>
       <LinkBox bg='gray.800' padding='6' rounded='lg'>
        <Box rounded='md' overflow='hidden' display='inline-flex'>
         <GatsbyImage
          image={fetchImage(node?.frontmatter?.icon)}
          alt={node?.frontmatter?.title}
         />
        </Box>
        <Heading size='md' as='h3' marginBottom='2' marginTop='3'>
         {node?.frontmatter?.title}
        </Heading>
        <Text opacity={0.7}> {node?.frontmatter?.excerpt}</Text>
       </LinkBox>
      </Link>
     ))}
    </SimpleGrid>
   </Box>
  </AppWrapper>
 );
};

export default Snippets;

export const query = graphql`
 query SnippetList {
  snippets: allMdx(
   sort: { order: DESC, fields: frontmatter___date }
   filter: { frontmatter: { section: { regex: "/snippets/" } } }
  ) {
   edges {
    node {
     id
     frontmatter {
      tag
      slug
      date
      title
      excerpt
      icon {
       childImageSharp {
        gatsbyImageData(
         height: 60
         width: 60
         placeholder: BLURRED
         quality: 100
         formats: AUTO
        )
       }
      }
     }
    }
   }
  }
 }
`;
