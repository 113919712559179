import React from 'react';
import { Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import AppWrapper from 'src/components/app-wrapper';
import { FiHome } from 'react-icons/fi';
import { Heading, Box, Image, Center, Button } from '@chakra-ui/react';

const NotFoundPage = () => {
 return (
  <AppWrapper>
   <SEO title='404 - This page could not be found (jideabdqudus)' />
   <Box>
    <Center>
     <Image src='/images/404.png' alt='404' height={{ base: '200', md: 'md' }} />
    </Center>
    <Center>
     <Heading
      fontSize={{ base: '2rem', md: '2rem' }}
      letterSpacing='tight'
      mb='2'
      mt='4'
      padding={'4'}
      bg='yellow.50'
      color={'black'}>
      Not found
     </Heading>
    </Center>
    <Center>
     <Link to='/'>
      <Button
       bg='transparent'
       _hover={{
        background: 'transparent',
        textDecor: 'underline',
       }}>
       <FiHome style={{ marginRight: '9px', marginTop: '1px' }} /> Go back Home
      </Button>
     </Link>
    </Center>
   </Box>
  </AppWrapper>
 );
};

export default NotFoundPage;
