import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import { useMDX } from 'src/utils/use-mdx';
import { FiArrowLeft } from 'react-icons/fi';
import AppWrapper from 'src/components/app-wrapper';
import MDXComponents from 'src/components/mdx-components';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box, Heading, Text, Divider, Icon, Flex, Container } from '@chakra-ui/react';

interface IProps {
 data: Queries.SnippetDetailsQuery;
}
const BookmarkDetails = ({ data }: IProps) => {
 const fetchImage: any = (image: IGatsbyImageData) => {
  return getImage(image);
 };
 const mdx = useMDX(data.mdx?.body);
 const Content = mdx?.default;
 return (
  <AppWrapper active='Snippets'>
   <SEO
    title={data?.mdx?.frontmatter?.title}
    description={data?.mdx?.frontmatter?.excerpt}
    pathname={`snippets/${data?.mdx?.frontmatter?.slug}`}
   />
   <Box>
    <Box ml={'5'}>
     <Link to='/snippets'>
      <Icon
       as={FiArrowLeft}
       mt='1px'
       color='black'
       bg='yellow.50'
       height={'40px'}
       width='40px'
       rounded={'5px'}
      />
     </Link>
    </Box>

    <Container maxW={'4xl'}>
     <Flex justify={'space-between'}>
      <Box>
       <Heading as='h1' mb='4' mt='16'>
        {data?.mdx?.frontmatter?.title}
       </Heading>
       <Text mb='8' fontSize={'20px'}>
        {data?.mdx?.frontmatter?.excerpt}
       </Text>
      </Box>
      <Box>
       <GatsbyImage
        image={fetchImage(data?.mdx?.frontmatter?.icon)}
        alt={data?.mdx?.frontmatter?.title || 'Icon'}
       />
      </Box>
     </Flex>
     <Divider mb='8' color='whiteAlpha.300' opacity={0.2} />
     <Box className='markdown-body prism'>
      <Content components={MDXComponents} />
     </Box>
    </Container>
   </Box>
  </AppWrapper>
 );
};

export default BookmarkDetails;

export const query = graphql`
 query SnippetDetails($slug: String!) {
  mdx(frontmatter: { slug: { eq: $slug } }) {
   id
   body
   frontmatter {
    tag
    title
    date
    excerpt
    slug
    icon {
     childImageSharp {
      gatsbyImageData(height: 60, width: 60, placeholder: BLURRED, quality: 100, formats: AUTO)
     }
    }
   }
  }
 }
`;
