import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import { formatDate } from 'src/utils/format-date';
import AppWrapper from 'src/components/app-wrapper';
import { Heading, Box, Text, VStack, HStack } from '@chakra-ui/react';

interface IProps {
 data: Queries.BookmarkListQuery;
}

const Bookmarks = ({ data }: IProps) => {
 const posts: Queries.BookmarkListQuery['bookmarks'] = data.bookmarks;

 return (
  <AppWrapper active='Bookmarks'>
   <SEO title='Bookmarks - Jide Abdul-Qudus - Software Engineer' pathname='bookmarks' />
   <Box>
    <Heading fontSize={{ base: '2rem', md: '3rem' }} letterSpacing='tight' mb='8' mt='8' fontFamily={'alternate'}>
     Bookmarks
    </Heading>
    <Box maxW={{ lg: '500' }}>
     <Text fontSize={{ base: 'lg', md: '1xl' }}>
      Some links about interesting topics that might be helpful to you today...or tomorrow
     </Text>
    </Box>
   </Box>
   <Box mt={'50px'}>
    <VStack spacing={4} align='stretch'>
     {posts.edges.map(({ node }: any) => (
      <Box key={node?.id}>
       <HStack>
        <Box>
         <Link to={`/bookmarks/${node?.frontmatter?.slug}`}>
          <Heading
           size='md'
           my='1'
           lineHeight={'35px'}
           textDecoration={'underline'}
           textUnderlineOffset={'6px'}
           _hover={{
            textDecoration: 'none',
           }}>
           {node?.frontmatter?.title}
          </Heading>
         </Link>
         <Text mb='1' opacity={0.7}>
          {node?.frontmatter?.excerpt}
         </Text>
         <Text fontSize={'11px'} color='yellow.50'>
          Updated on {formatDate(node?.frontmatter?.date, true)}.{' '}
         </Text>
        </Box>
       </HStack>
      </Box>
     ))}
    </VStack>
   </Box>
  </AppWrapper>
 );
};

export default Bookmarks;

export const query = graphql`
query BookmarkList {
  bookmarks: allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date },
     filter: {fileAbsolutePath: {regex: "/(bookmarks)/.*\\.md$/"}}) {
    edges {
      node {
        id
        frontmatter {
          tag
          slug
          date
          title
          excerpt
        }
      }
    }
  }
}
`;
