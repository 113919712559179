import React from 'react';
import { graphql, Link } from 'gatsby';
import { SEO } from 'src/components/seo';
import AppWrapper from 'src/components/app-wrapper';
import { formatDate } from 'src/utils/format-date';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Heading, Box, Text, VStack, LinkBox, HStack } from '@chakra-ui/react';

interface IProps {
 data: Queries.BlogListQuery;
}
const Blog = ({ data }: IProps) => {
 const posts: Queries.BlogListQuery['blogposts'] = data.blogposts;
 const fetchImage: any = (image: IGatsbyImageData) => {
  return getImage(image);
 };
 return (
  <AppWrapper active='Blog'>
   <SEO
    title="The Qoder's Blog (jideabdqudus)"
    description='Crafting Code, Engineering the Future, One line at a Time.
    I write about stuff i see around, This blog expresses my views on various technologies.'
    pathname='blog'
   />
   <Box>
    <Heading fontSize={{ base: '2rem', md: '3rem' }} letterSpacing='tight' mb='8' mt='8' fontFamily={'alternate'}>
     Blog
    </Heading>
    <Box maxW={{ lg: '500' }}>
     <Text fontSize={{ base: 'lg', md: '1xl' }}>
      A list of articles I've written around topics like Javascript, Design, Data structures
      and lots more.
     </Text>
    </Box>
   </Box>
   <Box mt={'50px'}>
    <VStack spacing={4} align='stretch'>
     {posts.edges.map(({ node }: any) => (
      <Box key={node.id}>
       <Link to={`/blog/${node?.frontmatter?.slug}`}>
        <LinkBox
         as='article'
         p='5'
         rounded='md'
         _hover={{
          background: 'gray.900',
          transform: 'translateX(-5px)',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'ease-in-out',
         }}>
         <HStack spacing={6}>
          <Box as='time' dateTime={node?.frontmatter?.date}>
           <GatsbyImage
            image={fetchImage(node?.frontmatter?.icon)}
            alt={node?.frontmatter?.title}
           />
          </Box>
          <Box>
           <Heading size='md' my='2'>
            {node?.frontmatter?.title}
           </Heading>
           <Text color={'yellow.50'}>
            Published on {formatDate(node?.frontmatter?.date, true)} •{' '}
            {node?.frontmatter?.readingTime} min read
           </Text>
          </Box>
         </HStack>
        </LinkBox>
       </Link>
      </Box>
     ))}
    </VStack>
   </Box>
  </AppWrapper>
 );
};

export default Blog;

export const query = graphql`
 query BlogList {
  blogposts: allMdx(
   filter: { frontmatter: { section: { regex: "/articles/" } } }
   sort: { order: DESC, fields: frontmatter___date }
  ) {
   edges {
    node {
     id
     frontmatter {
      tag
      title
      slug
      date
      readingTime
      icon {
       childImageSharp {
        gatsbyImageData(
         height: 60
         width: 60
         placeholder: BLURRED
         quality: 100
         formats: AUTO
        )
       }
      }
     }
    }
   }
  }
 }
`;
