import { graphql } from 'gatsby';
import { SEO } from 'src/components/seo';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AppWrapper from 'src/components/app-wrapper';
import { Box, Flex, Text, Button, Heading, Input, Spinner } from '@chakra-ui/react';

const Newsletter = () => {
 const {
  register,
  handleSubmit,
  formState: { errors },
 }: any = useForm();
 const [loading, setLoading] = useState(false);
 const [submitted, setSubmitted] = useState(false);
 const [submitMessage, setSubmitMessage] = useState('');

 const onSubmit = async (data: any) => {
  setLoading(true);
  const email = data.email;
  try {
   const requestBody = {
    query: `
          mutation SubscribeToNewsletter($input: SubscribeToNewsletterInput!) {
            subscribeToNewsletter(input: $input) {
              status
            }
          }
        `,
    variables: {
     input: {
      publicationId: `${process.env.GATSBY_NEWSLETTER_PUBLICATION_ID}`,
      email: email,
     },
    },
   };

   const response = await fetch(`${process.env.GATSBY_NEWSLETTER_URL}`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
   });

   const responseData = await response.json();

   if (!response.ok) {
    setSubmitted(true);
    setLoading(false);
    setSubmitMessage('Something went wrong. Please try again.');
    return;
   }

   if (responseData.hasOwnProperty('errors')) {
    setSubmitted(true);
    setLoading(false);
    setSubmitMessage('Something went wrong. Please try again.');
    return;
   }
   setSubmitted(true);
   setLoading(false);
   setSubmitMessage(
    'Thank you for subscribing! Please confirm your subscription by clicking the link in your email.'
   );
   return;
  } catch (error) {
   setSubmitted(true);
   setLoading(false);
   setSubmitMessage('Something went wrong. Please try again.');
  }
 };
 return (
  <AppWrapper>
   <SEO title='Newsletter - Jide Abdul-Qudus - Software Engineer' pathname='newsletter' />
   <Box>
    <Heading
     fontSize={{ base: '2rem', md: '3rem' }}
     letterSpacing='tight'
     mb='8'
     mt='8'
     fontFamily={'alternate'}>
     Newsletter
    </Heading>
    <Box maxW={{ lg: '500' }}>
     <Text fontSize={{ base: 'lg', md: '1xl' }}>
      Read articles from my Blog directly inside your 📬 inbox. Subscribe to my newsletter, and
      don't miss out 😉
     </Text>
    </Box>
   </Box>
   <Box mt={'50px'} maxW={'52rem'}>
    <Box>
     <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap='4' marginTop='8' direction={{ base: 'column', md: 'row' }}>
       <Input
        required
        flex='1'
        paddingX='6'
        autoComplete='email'
        paddingY='5'
        height={'64px'}
        aria-label='Enter your email address'
        bg='#1e1e1e'
        rounded='lg'
        border={'none'}
        placeholder='you@email.com'
        _placeholder={{ color: 'whiteAlpha.400' }}
        {...register('email', {
         required: 'Email is required',
         pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address',
         },
        })}
       />
       <Button
        minW='10.8rem'
        type='submit'
        aria-label='Enter your email address'
        fontWeight='bold'
        letterSpacing='wide'
        height={'64px'}
        display='inline-flex'
        alignItems='center'
        justifyContent='center'
        textTransform='uppercase'
        color='black'
        paddingX='10'
        paddingY='5'
        rounded='xl'
        disabled={loading}
        transition='background 0.2s ease-in-out'
        bg='yellow.50'
        _hover={{ bg: 'yellow.100' }}
        _active={{ bg: 'yellow.200' }}>
        {loading ? <Spinner size='md' /> : 'Subscribe'}
       </Button>
      </Flex>
     </form>

     <Box maxWidth='32rem' mt='10'>
      {errors.email?.message}
      <Text>{submitted ? submitMessage : ''}</Text>
     </Box>
    </Box>
   </Box>
  </AppWrapper>
 );
};

export default Newsletter;

export const query = graphql`
 query {
  site {
   siteMetadata {
    description
   }
  }
 }
`;
